import { post } from '../../AuthRequests';
import { bffServiceUrl } from '../../netconfig';
import { getCookie, removeCookie, setCookie } from './cookies';
import { NoRefreshTokenError } from './errors';
import { type AuthTokenResponse, type ReCaptchaResult } from './types';

export type LoginCredentials = {
  email: string;
  password: string;
};

export const ID_TOKEN = 'idToken';
export const REFRESH_TOKEN = 'refreshToken';

export const GCID = 'gcId';
export const REDIRECT_TO_PATH = 'redirectToPathname';

const areAuthTokensSet = (): boolean => getRefreshToken() !== undefined;
const getIdToken = () => getCookie(ID_TOKEN);
const getRefreshToken = () => getCookie(REFRESH_TOKEN);

/**
 * Generates a fresh id token using the existing id token, if exists.
 * If there's no valid refresh token, throws an exception.
 */
const refreshAuthTokens = async () => {
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    throw new NoRefreshTokenError();
  }
  const { data: authData } = await post<AuthTokenResponse>(
    `${bffServiceUrl}/auth/token`,
    { token: refreshToken },
    { auth: false },
  );
  setAuthTokens(authData);
};

/**
 * Sets the auth tokens generated in the server as cookies, to be managed by the
 * platform across sessions and network requests.
 * @param id_token - authorization token used in every auth network request.
 * @param expires_at - id token's expiration date.
 * @param refresh_token - used for generating a new id token, if expired.
 */
export const setAuthTokens = ({
  id_token,
  refresh_token,
  expires_at,
}: AuthTokenResponse) => {
  const idTokenExpirationDate = new Date(expires_at * 1000);

  setCookie(REFRESH_TOKEN, refresh_token);
  setCookie(ID_TOKEN, id_token, { expires: idTokenExpirationDate });
};

/**
 * Main authentication endpoint for authenticating a user with the given
 * credentials. On success, returns both ID token and refresh token to be stored
 * as cookies.
 * @param credentials - username (email) and password.
 */
export const authenticateCredentials = async (
  credentials: LoginCredentials,
): Promise<void> => {
  const { data: authData } = await post<AuthTokenResponse>(
    `${bffServiceUrl}/auth/login`,
    {
      email: credentials.email,
      password: credentials.password,
      client_id: 'web',
    },
    { auth: false },
  );
  setAuthTokens(authData);
};

/**
 * Given a challenge token containing the challenge score and results, verify
 * it against Google's reCaptcha API with our remote reCaptcha secret.
 * @param token - challenge token generated by Google.
 */
export const verifyReCAPTCHAToken = async (
  token: string,
): Promise<ReCaptchaResult> => {
  const { data } = await post<ReCaptchaResult>(
    `${bffServiceUrl}/auth/recaptcha`,
    {
      recaptcha_challenge_token: token,
    },
    { auth: false },
  );

  return data;
};

/**
 * Checks whether there's an existing, valid, refresh token.
 */
export const isRefreshTokenActive = () => !!getRefreshToken();

/**
 * Checks whether there's an existing, valid, ID token.
 */
export const isIdTokenActive = () => !!getIdToken();

/**
 * Returns the current ID token to be used as an authorization header to
 * auth requests:
 * - If there's no tokens (id token nor refresh token) - returns null (no authenticated user).
 * - If there's no ID token, try to use the existing refresh token to generate a new ID token.
 * - If an ID token exists, returns it (user is authenticated).
 */
export const fetchIdToken = async (): Promise<string | null> => {
  if (!areAuthTokensSet()) {
    return null;
  }

  if (!isIdTokenActive()) {
    await refreshAuthTokens();
  }

  return getIdToken() ?? null;
};

/**
 * Triggers a reset password email to start Firebase's 3rd-party password
 * regeneration process.
 * @param email - the email address to send the reset request to.
 */
export const sendPasswordResetEmail = async (email: string) => {
  await post<void>(
    `${bffServiceUrl}/auth/reset_password`,
    {
      request: { email, action: 'send_email_by_provider' },
    },
    { auth: false },
  );
};

/**
 * Invalidates the current tokens and removes them from the user's cookies.
 * De-authenticates the currently authenticated user, if exists.
 * @param authenticated - whether there's a current authenticated user.
 */
export const revokeAuth = async (authenticated: boolean = true) => {
  if (authenticated && import.meta.env.VITE_APP_THEME === 'breeze') {
    await post<void>(`${bffServiceUrl}/auth/logout`);
  }

  removeCookie(ID_TOKEN);
  removeCookie(REFRESH_TOKEN);
  removeCookie(GCID);
};
